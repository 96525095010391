/* App.css */
@import url("https://fonts.googleapis.com/css?family=Inter:700,500,400");

:root {
  --black: rgba(0, 0, 0, 1);
  --blue-stone-2: rgba(0, 102, 102, 0.38);
  --blue-stone: rgba(0, 102, 102, 1);
  --eerie-black: rgba(32, 32, 32, 1);
  --white: rgba(255, 255, 255, 1);
  --light-2: #fdfdfd;
  --font-size-s: 9px;
  --font-size-m: 18px;
  --font-size-l: 24px;
  --font-size-xl: 48px;
  --font-size-xxl: 72px;
  --font-size-xxxl: 96px;
  --font-family-montserrat: "Montserrat";
  --font-family-roboto: "Roboto";
  --font-family-rye: "Rye";
}

.App {
  margin: 0 auto;
  font-family: "Inter";
  padding-bottom: 30px;
  box-sizing: border-box;
}

.container {
  max-width: 1320px;
  padding: 40px;
  margin: 0 auto;
  padding-top: 22px;
}

/* Header */
.header {
  position: relative;
}
.header__background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.header__container {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 40px;
  max-width: 1320px;
  margin: 0 auto;
  gap: 24px;
  padding-bottom: 73px;
  padding-top: 73px;
}
.header-home .header__logo {

  height: auto;
}
.header__logo {

  height: auto;
}
.header__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.header__title-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.header-home .header__title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  margin: 0;
}
.header-home .header__subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
}
.header__title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  margin: 0;
}
.header__subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  max-width: 536px;
}

.header__divider {
  align-self: stretch;
  background-color: #1e1e1e;
  height: 1px;
  max-width: 536px;
  width: 536px;
}

.header__details {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.header__details-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header__details-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  white-space: nowrap;
  width: 48%;
}

.header__details-value {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 50%;
  white-space: nowrap;
}

/* List */
.list__container {
  padding: 40px;
  max-width: 1320px;
  margin: 0 auto;
}

.list__title-container {
  max-width: 430px;
  padding-top: 7px;
  margin-bottom: 48px;
}
.list__title-container > h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 0;
}
.list__title-container > h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 11px;
}

/* Tiles */
.tiles-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 24px;
  column-gap: 26px;
  justify-content: center;
}

.tile {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(30, 30, 30, 0.1);
}
.tile__container {
  display: flex;
  flex-direction: column;
  max-width: 312px;
  height: 290px;
  width: 312px;
}

.tile__image {
  max-width: 312px;
  max-height: 250px;
  width: 312px;
  height: 250px;
  border-radius: 10px;
  cursor: pointer;
  object-fit: cover;
  aspect-ratio: 5/4;
}

.tile__content {
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
}

.tile__title {
  font-family: "Inter";
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  margin-top: 2px;
  text-transform: capitalize;
}

.tile__subtitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 6px 12px;
  padding-top: 5px;
  color: var(--light-2);
  border-radius: 49px;
  height: 16px;
  text-transform: capitalize;
  padding-top: 2px;
}

@media screen and (max-width: 768px) {
  .tiles-container {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  }
  .list__container {
    padding: 24px;
    padding-top: 0;
  }
  .tile__container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    height: 290px;
    width: 100%;
  }
  .tile__image {
    max-width: 100%;
    width: 100%;
  }
  .list__title-container {
    margin-bottom: 22px;
  }
  /* .{
    margin: 0 auto;
  } */
}

/*Popup*/

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  width: 90%;
  max-width: 1030px;
  height: auto;
  padding-bottom: 48px;
  max-height: 90%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: #fdfdfd;
}

.popup__head-container {
  padding: 48px;
  display: flex;
  gap: 24px;
}

.popup__logo {
  width: 153px;
  height: 122px;
  border-radius: 10px;
  object-fit: cover;
}

.popup__title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 10px;
  margin-top: -2px;
  text-transform: capitalize;
}
.popup__subtitle {
  max-width: 451px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}

.popup__close {
  position: absolute;
  top: 48px;
  right: 48px;
  width: 36px;
  height: 36px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

/* Default styles */
.concert__title-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 52px;
}

.concert__title-date,
.concert__title-events {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  padding: 14px 0;
}

.concert__title-events {
  width: 59%;
}

.concert__title-date {
  padding-left: 48px;
  width: 39%;
}

.concert__container {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
}

.concert__item-container {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  align-items: center;
  min-height: 64px;
  background: #fdfdfd;
  border: 1px solid #f7f8fa;
  border-top: none;
}

.concert__date,
.concert__events {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 14px 0;
}

.concert__events {
  width: 61%;
  align-items: center;
  display: flex;
}
.concert__events-location {
  width: 60%;
}
.concert__events-date {
  width: 40%;
}
.concert__date {
  padding-left: 48px;
  width: 40%;
  align-items: center;
  display: flex;
}
.concert__date-location,
.concert__date-time,
.concert__date-date {
  width: 33%;
}
.concert__date-wrapper {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  width: 90%;
  gap: 16px;
}

.concert__events-wrapper {
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding: 10px 0;
  box-sizing: border-box;
}

.concert__details {
  margin-left: auto;
  padding-right: 48px;
}

.concert__details-btn {
  margin-left: auto;
  padding: 8px 24px;
  cursor: pointer;
  border-radius: 49px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--light-2);
  text-decoration: none;
  padding-top: 5px;
}

/*detail page*/
.detail-title {
  margin-top: 53px;
  height: 66px;
  display: flex;
  align-items: center;
}
.detail__container-bottom {
  display: flex;
  align-items: center;
}
.detail__container-bottom .concert__title-date {
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 20px;
  line-height: 24px;
}
.detail-title .concert__title-date {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.detail__container {
  padding: 48px;
  margin: 0 auto;
  background: #f7f8fa;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.detail__container-text {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 17px;
  width: 50%;
}
.detail__text-details .header__details-label {
  width: 20%;
}
.detail__text-details .header__details-value {
  width: 62%;
}
.detail__text-details .header__details-container {
  gap: 30px;
}
.detail__image {
  border-radius: 10px;
  max-width: 580px;
  max-height: 466px;
}
.detail__text-title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 35px;
  margin: 0;
  margin-top: 5px;
  margin-bottom: -16px;
}
.detail__text-description > h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
  margin-bottom: 10px;
}
.detail__text-description > span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.detail__text-btn {
  text-align: center;
  margin-top: 12px;
  padding: 12px 24px;
  cursor: pointer;
  color: var(--light-2);
  border-radius: 49px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  max-width: 277px;
  padding-top: 8px;
}
.detail__link-home {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 48px;
}

/* Responsive styles */
@media only screen and (max-width: 767px) {
  .concert__date-location,
  .concert__date-time,
  .concert__date-date,
  .concert__events-date {
    width: 100%;
  }
  .detail__link-home {
    padding: 24px;
  }
  .detail__text-title > h2 {
    margin-top: 0;
    font-size: 22px;
  }
  .detail__text-title {
    margin-top: -5px;
  }
  .concert__title-container {
    flex-direction: row;
    height: auto;
  }

  .concert__title-events {
    width: 50%;
    padding-left: 20px;
  }

  .concert__title-date {
    width: 50%;
    padding-left: 20px;
  }

  .concert__item-container {
    flex-direction: row;
    height: auto;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .concert__date {
    width: 50%;
    padding-left: 0;
    padding-top: 10px;
  }

  .concert__events {
    width: 50%;
    padding-top: 10px;
    flex-direction: column;
  }
  .concert__date-wrapper {
    justify-content: baseline;
    padding-top: 0;
    width: 100%;
    padding: 20px;
  }
  .concert__events-wrapper {
    width: 100%;
    padding: 20px;
    max-width: 100%;
  }
  .concert__date-wrapper,
  .concert__events-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .concert__details-btn {
    margin: 0 auto;
    margin-top: 10px;
  }
  .concert__details {
    display: none;
  }
  .concert__item-container .concert__details-mobile {
    display: block;
    flex-basis: 100%;
    padding: 20px;
    padding-top: 0;
    max-width: 96px;
  }
  .concert__date-wrapper {
    gap: 0;
  }
}

.concert__details-mobile {
  display: none;
}
/*Detail Page*/
@media screen and (max-width: 768px) {
  .container {
    padding: 0;
  }
  .detail__container {
    padding: 24px;
    gap: 24px;
    flex-direction: column-reverse;
  }
  .detail-title .concert__title-date {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    padding-left: 24px;
    width: 100%;
  }
  .detail__text-btn {
    margin-top: 16px;
  }

  .detail__container-text {
    padding-top: 0;
    width: 100%;
  }

  .detail__image {
    width: 100%;
    height: auto;
    max-width: none;
  }
  .container > .detail__container .header__details-container {
    gap: 0;
  }
  .concert__date,
  .concert__events,
  .concert__events-location,
  .concert__events-date {
    font-size: 12px;
  }
  .container > .concert__container .concert__title-date {
    padding-left: 24px;
  }
  .container > .concert__container .concert__item-container {
    border: 1px solid #f7f8fa;
  }
  .container > .concert__container .concert__details-mobile {
    padding-left: 24px;
  }
  .detail__text-description > span {
    margin-bottom: 32px;
    display: block;
  }
  .detail__text-description > span:last-child {
    margin-bottom: 0;
    display: block;
  }
  .container > .concert__container .concert__date-wrapper {
    padding-left: 24px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .detail__text-title > h2 {
    margin-top: 0;
  }
  .detail__container {
    flex-wrap: wrap;
    padding: 36px;
    flex-direction: column-reverse;
  }

  .detail__container-text {
    width: 90%;
    margin-right: 5%;
  }

  .detail__image {
    width: 100%;
    margin-bottom: 0;
    margin-left: auto;
    max-width: none;
  }

  .detail__text-btn {
    max-width: 100%;
  }
}

/* Mobile styles */

@media screen and (max-width: 767px) {
  .header__container {
    flex-direction: column;
    padding: 24px;
  }
  .header-home .header__logo,
  .header__logo {
    max-width: 140px;
    margin-bottom: -10px;
    height: auto;
  }

  .header__content {
    gap: 16px;
  }

  .header__title-container {
    text-align: left;
    gap: 8px;
  }

  .header__subtitle {
    font-size: 14px;
    line-height: 20px;
    max-width: 100%;
    text-align: left;
  }

  .header__divider {
    min-width: 100%;
    width: auto;
  }

  .header__details {
    max-width: none;
    text-align: left;
  }

  .header__details-container {
    flex-direction: column;
    gap: 8px;
    margin-bottom: 14px;
  }

  .header__details-container:last-child {
    margin-bottom: 0;
  }

  .header__details-label {
    width: 100%;
  }

  .header__details-value {
    width: 100%;
  }

  .popup__head-container {
    flex-direction: column;
    padding: 20px;
    padding-top: 80px;
  }
  .popup__content {
    max-width: 100%;
  }
  .popup__title {
    word-break: break-all;
    font-size: 22px;
  }
  .popup__close {
    top: 20px;
    right: 20px;
    padding: 0;
  }
  .popup__logo {
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
  }
}
